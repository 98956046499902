import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { addToStoreData, clearToStoreData, getToStoreData } from '../plugins/storage';
import styled from 'styled-components';

const GlobalNavbar = ({ editor, groupName, setEditor, isStale, saveDesign }) => {

  const [subject, setSubject] = useState("Add your subject line here");
  const [subHeader, setSubHeader] = useState(false);
  // const [userInfo, setUserInfo] = useState({});

  const { pathname } = useLocation();
  const history = useNavigate();
  const communicateRouteMatch = useMatch("/communicate");

  useEffect(() => {
    if (pathname === '/editor') {
      setSubHeader(true);
    }

    const communicate_data = getToStoreData("communicate_data");
    if (communicate_data) {
      const data = JSON.parse(communicate_data);
      setSubject(data.title);
    }
  }, [pathname]);

  // Store the subject in storage.
  useEffect(() => {
    if (subject) {
      addToStoreData("subject", subject);
    }
    if (subject && subHeader) {
      setEditor({ ...editor, subject: subject })
    }
  }, [subject, subHeader])

  // const handlePreview = () => {
  //   const previewIcon = document.querySelector(".arco-icon-eye");
  //   const parentElement = previewIcon.parentNode;
  //   parentElement && parentElement.click();
  //   console.log("Preview button clicked");
  //   // Perform action for Preview button
  // }

  const handleLogout = () => {
    clearToStoreData();
    history("/unauthorized");
  }

  const handleHomeBack = () => {
    history("/communicate");
  }

  const subjectChange = (event) => {
    setSubject(event.target.value);
  }
  const LEAVE_MESSAGE = 'You are leaving without saving your template. Are you sure?';

  const handleRedirect = (e, to) => {
    if (subHeader) {
      const data = {
        title: subject,
        email_body_json: JSON.stringify(editor.json),
        email_body: JSON.stringify(editor.html)
      }
      addToStoreData("communicate_data", JSON.stringify(data));
    }
    if (isStale) {
      e.preventDefault();
      if (window.confirm(LEAVE_MESSAGE)) {
        history(to);
      }
    }
  };

  return (
    <Wrapper>
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          {/* <!-- Logo and Description on the left --> */}
          <Link className="navbar-brand" onClick={handleHomeBack} to="#">
            <img src="/images/Icon-black-Vault.svg" alt="Logo" width="35" height="35" className="d-inline-block align-top mr-2" />
            <small className="text-muted ml-2"><div className="logged-in-message">You are logged in to Communicator</div></small>
          </Link>

          {/* <!-- Buttons on the right --> */}
          <div className="d-flex align-items-center">
            <button className="btn btn-link logoutBtn" type="button" onClick={handleLogout}>
              <img src="/images/logout.png" style={{ width: "25px" }} alt='logout_icon' />
            </button>
          </div>
        </div>
      </nav>
      {groupName &&
        <div className="row navBuilder logged-in-message container-fluid sub-navbar">
          <div className="col-md-6 d-flex gap-1 align-items-center">
            <img src="/images/home.png" alt="home" className="d-inline-block mr-2" />
            <p className="text-muted ml-2 mb-0">
              Editing group: <span className='ml-2 fw-bold' style={{ color: "#1f1f1f", fontFamily: "Bukra-Bold" }}>{groupName}</span>
            </p>
          </div>
          {!communicateRouteMatch &&
            <div className="col-md-6 d-flex justify-content-end">
              <ul className='sub-Nav-link font-Bukra'>
                <li><NavLink to="/communicate" onClickCapture={(e) => handleRedirect(e, "/communicate")} >Home</NavLink></li>
                <li><NavLink to="/editor">Design</NavLink></li>
                <li><NavLink to="/user-list" onClickCapture={(e) => handleRedirect(e, "/user-list")}>Send</NavLink></li>
                <li><NavLink to="/RSVP-list" onClickCapture={(e) => handleRedirect(e, "/RSVP-list")}>RSVP's</NavLink></li>
              </ul>
            </div>}
        </div>}
      {subHeader &&
        <div className="row navBuilder logged-in-message container-fluid">
          <div className="col-md-6">
            <form>
              <div className="input-group">
                <span className="template-subject">Subject</span>
                <input type="text" name="subject" placeholder="Enter email subject..." className="form-control titleInput" onChange={subjectChange} value={subject} />
                {/* <button className="btn btn-primary btn-gray rounded-3 ms-2 z-1" type="button" onClick={handlePreview}>Preview</button> */}
                <button type='button' className="btn btn-primary btn-Vault ms-2 rounded-2 px-3 z-1" onClick={() => saveDesign("save")}>Save</button>
              </div>
            </form>
          </div>
        </div>}
    </Wrapper >
  )
}

const Wrapper = styled.div`
  .dropdown-divider{
    margin: 0 !important;
  }

  .dropdown-menu{
    padding: 0 0 !important;
  }

  .dropdown-item.active, .dropdown-item:active{
    background-color: #565e64 !important;
  }

  .text-label{
    margin-bottom: 0;
    font-size: 12px;
    background: #92edce;
    padding: 10px 5px 10px 19px;
    border-radius: 5px;
    color: #000000;
  }

  .sub-Nav-link{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 25px;
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .sub-Nav-link a{
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s linear;
  }

  .sub-Nav-link a.active, .sub-Nav-link a:hover{
    color: #3B8967;
    font-weight: bold;
  }

  .sub-navbar{
    height: 60px;
  }
`

export default GlobalNavbar
